// Fungsi untuk memetakan deskripsi menjadi jenis transaksi dalam konteks Kompack
function mapDescriptionToText(desc) {
  const descriptionMapping = {
    outbound_fee: 'Outbound',
    outbound_goods: 'Penarikan Barang',
    payment_low_moving_stock: 'Biaya Low Moving Stok',
  }
  return descriptionMapping[desc] || 'Penarikan Saldo'
}

export default mapDescriptionToText
