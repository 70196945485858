import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  BButton,
  BPagination,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
  last365,
  kompackDate,
} from '@/store/helpers'
import mapDescriptionToText from '@/libs/kompackDescriptionMapper'
import Card from '../components/Card.vue'
import { detailSaldo } from '../tableconfig'

export default {
  components: {
    DateRangePicker,
    BPagination,
    BTable,
    BButton,
    BSpinner,
    Card,
    vSelect,
  },
  data() {
    return {
      fields: detailSaldo,
      pageOptions: [20, 50, 100, 200],
      // Date Picker state
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        'Semua waktu': [kompackDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      loading: true,
    }
  },
  mounted() {
    this.$store.dispatch('saldoDetail/init')
      .then(() => { })
      .catch(err => {
        this.alertError()
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapFields('saldoDetail', [
      'dateRange', 'table', 'partnerSelected',
    ]),
    ...mapGetters('saldoDetail', ['detail', 'transactions', 'saldoDetail', 'listPartners']),
  },
  watch: {
    'table.currentPage': {
      handler() {
        this.loading = true
        this.$store.dispatch('saldoDetail/getBalanceHistory')
          .then(() => { })
          .catch(err => {
            this.alertError()
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
    'table.perPage': {
      handler() {
        this.loading = true
        this.$store.dispatch('saldoDetail/getBalanceHistory')
          .then(() => { })
          .catch(err => {
            this.alertError()
          })
          .finally(() => {
            this.loading = false
          })
      },
      deep: true,
    },
  },
  beforeMount() {
    this.$store.commit('saldoDetail/RESET_STORE')
  },
  methods: {
    mapDescriptionToText,
    alertError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text:
            'Unable to get the data. Please try again later or contact support.',
          variant: 'danger',
        },
      })
    },
    formatRibuan(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatRupiah(x) {
      return `Rp ${this.formatRibuan(x)}`
    },
    formatRupiahTopup(x) {
      return `${this.formatRibuan(x)}`
    },
    formatDate(d) {
      return moment(d).format('D MMM')
    },
    formatDateYear(d) {
      return moment(d).format('D MMM YYYY')
    },
    formatNumber(n) {
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    setPage(perPage) {
      this.table.currentPage = 1
      this.$store.commit('saldoDetail/UPDATE_PER_PAGE', perPage)
    },
    selectPartner() {
      this.loading = true
      this.$store.dispatch('saldoDetail/getBalanceHistory')
        .then(() => { })
        .catch(err => {
          this.alertError()
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDetail(userId, id) {
      this.$store.commit('withdrawDetail/UPDATE_USER_ID', userId)
      this.$router.push(`/saldo/detail-penarikan/${id}`)
    },
  },
}
